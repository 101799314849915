import cardValidator from "card-validator";
import phoneFormatter from "phone-formatter";

const removeNonDigits = (str) => str.replace(/\D/g, "");
const removeNonDateCharacters = (str) => str.replace(/[^0-9/]/g, "");
const limitNumOfDigits = (maxLength) => (str) => str.slice(0, maxLength);

export function parsePhoneNumber(value) {
  return limitNumOfDigits(10)(removeNonDigits(phoneFormatter.normalize(value)));
}

export function formatPhoneNumber(value) {
  const firstGroup = `(${value.slice(0, 3)})`;
  const secondGroup = value.slice(3, 6);
  const thirdGroup = value.slice(6);

  if (value.length > 6) {
    return `${firstGroup} ${secondGroup}-${thirdGroup}`;
  } else if (value.length > 3) {
    return `${firstGroup} ${secondGroup}`;
  }

  return value;
}

export function parseExpDate(value) {
  const {month, year} = cardValidator.expirationDate(value);

  // Allows auto formatting on paste when valid numbers
  if (value.length > 3 && month && year) {
    return `${month}/${year.length > 2 ? year.slice(2) : year}`;
  }

  const digitsOnly = removeNonDigits(value);
  if (digitsOnly.length > 2 && value.indexOf("/") === -1) {
    return `${digitsOnly.slice(0, 2)}/${digitsOnly.slice(2)}`;
  }

  return limitNumOfDigits(5)(removeNonDateCharacters(value));
}

export function formatExpirationDate(value = "") {
  return value;
}

export const parseCVC = (cvcLength) => (value) => {
  return limitNumOfDigits(cvcLength)(removeNonDigits(value || ""));
};

export function parseZip(value) {
  return limitNumOfDigits(5)(removeNonDigits(value || ""));
}

export function parseCreditCardNumber(value) {
  return limitNumOfDigits(16)(removeNonDigits(value || ""));
}

export function formatCreditCardNumber(value) {
  const {card} = cardValidator.number(value);
  let valueArr = value.split("");

  if (card?.gaps?.length > 0) {
    const gaps = card.gaps;
    let offset = 0;

    while (gaps.length > 0) {
      const currentGap = gaps.shift();
      if (currentGap >= value.length) {
        break;
      }

      valueArr.splice(currentGap + offset, 0, " ");

      offset++;
    }
  }

  return valueArr.join("");
}
