import classNames from "classnames";
import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import BootstrapForm from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";
import {Field, Form} from "react-final-form";
import TextField from "../../Shared/Form/TextField";
import {
  formatPhoneNumber,
  parsePhoneNumber,
} from "../../Shared/Form/formatters";
import {
  composeValidators,
  required,
  validateEmail,
  validateMobilePhone,
} from "../../Shared/Form/validators";
import styles from "./ReservationSubmitionForm.module.scss";
import CreditCardInformationFields from "../../Shared/ReservationForm/CreditCardInformationFields";
import ReserveNowDisclosures from "../../Reserve/ReserveNowDisclosures";

const ConfirmForm = ({onSubmit, tcModalContent}) => {
  return (
    <Form
      initialValues={{
        firstName: "",
        lastName: "",
        mobileNumber: "",
        email: "",
        expDate: "",
        cardNumber: "",
        cvc: "",
        zip: "",
        cardHolderName: "",
      }}
      onSubmit={onSubmit}
      render={({handleSubmit, submitting}) => (
        <form onSubmit={handleSubmit}>
          <div
            className={classNames(
              "container-fluid use-default gx-3 gx-lg-4",
              styles.confirmFormBox
            )}
          >
            <Row className="g-2 mb-3">
              <Col xs={6}>
                <Field name="firstName" validate={required}>
                  {(props) => (
                    <TextField
                      {...props}
                      labelClassName="d-block"
                      label="First Name"
                    />
                  )}
                </Field>
              </Col>
              <Col xs={6}>
                <Field name="lastName" validate={required}>
                  {(props) => (
                    <TextField
                      {...props}
                      labelClassName="d-block"
                      label="Last Name"
                    />
                  )}
                </Field>
              </Col>
              <Col xs={6}>
                <Field
                  name="mobileNumber"
                  format={formatPhoneNumber}
                  parse={parsePhoneNumber}
                  validate={composeValidators(required, validateMobilePhone)}
                >
                  {(props) => (
                    <TextField
                      {...props}
                      type="tel"
                      autoComplete="tel"
                      labelClassName="d-block"
                      label="Mobile #"
                    />
                  )}
                </Field>
              </Col>
              <Col xs={6}>
                <Field
                  name="email"
                  validate={composeValidators(required, validateEmail)}
                >
                  {(props) => (
                    <TextField
                      {...props}
                      autoComplete="email"
                      labelClassName="d-block"
                      label="Email"
                    />
                  )}
                </Field>
              </Col>
            </Row>
            <hr />
            <BootstrapForm.Label className={styles.formGroupLabel}>
              Payment Information
            </BootstrapForm.Label>
            <CreditCardInformationFields />
            <ReserveNowDisclosures
              className="mt-2"
              submitText={"Confirm & Pay"}
              tcModalContent={tcModalContent}
            />
          </div>
          <div className="container use-default gx-3 gx-lg-4">
            <Button
              className={`fw-bold w-100 mt-2 mt-md-3 ${styles.submitButton}`}
              disabled={submitting}
              type="submit"
              variant="secondary"
            >
              {submitting && (
                <Spinner
                  className="me-2"
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              )}
              <span>Place reservation</span>
              <span>$100.00</span>
            </Button>
          </div>
        </form>
      )}
    />
  );
};

export default ConfirmForm;
