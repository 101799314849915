import useSWR from "swr";
import {getPageByTitle} from "../../lib/butter-api";

export function useTerms() {
  return useSWR("/reservation-terms", termsFetcher, {
    fallbackData: {
      legalContent: {
        fields: {
          body: [
            {
              fields: {
                refundable_legal: "",
                refundable_title: "",
              },
            },
          ],
        },
      },
      refundableModalContent: {},
      tcModalContent: {},
    },
  });
}

async function termsFetcher() {
  // ! Sets whether to use the preview butter content I believe. This forces it to production. There is likely an existing way to hook into the context to find and use here.
  const preview = 0;

  return Promise.all([
    getPageByTitle("refundable-modal", preview),
    getPageByTitle("reservationterms", preview),
    getPageByTitle("tos", preview),
    getPageByTitle("privacy", preview),
    getPageByTitle("legal", preview),
    getPageByTitle("subscriptiontos", preview),
  ]).then(
    ([
      refundableModalContent,
      reservationTermsContent,
      tosContent,
      privacyContent,
      legalContent,
      subscriptionTos,
    ]) => {
      return {
        refundableModalContent,
        legalContent,
        tcModalContent: {
          reservationTermsContent: reservationTermsContent?.fields?.body?.[0],
          tosContent: tosContent?.fields?.body?.[0],
          privacyContent: privacyContent?.fields?.body?.[0],
          subscriptionTos: subscriptionTos?.fields?.body?.[0],
        },
      };
    }
  );
}
