import classNames from "classnames";
import React, {useMemo} from "react";
import {Field, useFormState} from "react-final-form";
import cardValidator from "card-validator";
import TextField from "../Form/TextField";
import CardNumberForm from "./CardNumberForm";
import {
  validateCreditCardNumber,
  validateCsv,
  validateExpDate,
  validatePostalCode,
  required,
} from "../Form/validators";
import styles from "./ReservationForm.module.scss";
import {
  formatCreditCardNumber,
  formatExpirationDate,
  parseCreditCardNumber,
  parseCVC,
  parseExpDate,
  parseZip,
} from "../Form/formatters";
import {Col, Row} from "react-bootstrap";

function getErrors(errors, touched) {
  const fields = ["cardNumber", "expDate", "cvc", "zip"];
  return Object.entries(errors)
    .filter(([key, value]) => fields.includes(key) && value && touched[key])
    .map(([key, value]) => ({
      name: key,
      error: value,
    }));
}

export default function CreditCardInformationFields() {
  const {values, errors, modifiedSinceLastSubmit, submitError, touched} =
    useFormState();
  const creditCardErrors = getErrors(errors, touched);
  const hasCreditCardErrors = creditCardErrors.length > 0;

  const cvcLength = useMemo(() => {
    const {card} = cardValidator.number(values.cardNumber);
    return card?.code.size;
  }, [values.cardNumber]);

  return (
    <React.Fragment>
      <div
        className={classNames({
          [styles.hasErrors]: hasCreditCardErrors,
        })}
      >
        <Row className="g-2 mb-3">
          <Col xs={12}>
            <Field name="cardHolderName" validate={required}>
              {(props) => (
                <TextField
                  {...props}
                  labelClassName="d-block"
                  label="Name on Card"
                />
              )}
            </Field>
          </Col>
        </Row>
        <Row className="g-2 mb-3">
          <Col xs={12}>
            <Field
              name="cardNumber"
              format={formatCreditCardNumber}
              parse={parseCreditCardNumber}
              validate={validateCreditCardNumber}
            >
              {(props) => (
                <CardNumberForm
                  {...props}
                  autoComplete="cc-number"
                  className={styles.cardNumberField}
                  ignoreError
                  placeholder="1234 1234 1234 1234"
                />
              )}
            </Field>
          </Col>
        </Row>
        <Row className="g-2 mb-3">
          <Col xs={4}>
            <Field
              name="expDate"
              format={formatExpirationDate}
              parse={parseExpDate}
              validate={validateExpDate}
            >
              {(props) => (
                <TextField
                  {...props}
                  autoComplete="cc-exp"
                  ignoreError
                  label="MM/YY"
                />
              )}
            </Field>
          </Col>
          <Col xs={4}>
            <Field
              name="cvc"
              parse={parseCVC(cvcLength)}
              validate={validateCsv}
            >
              {(props) => (
                <TextField
                  {...props}
                  autoComplete="cc-csc"
                  ignoreError
                  label="CVC"
                />
              )}
            </Field>
          </Col>
          <Col xs={4}>
            <Field name="zip" parse={parseZip} validate={validatePostalCode}>
              {(props) => (
                <TextField
                  {...props}
                  autoComplete="billing postal-code"
                  ignoreError
                  label="ZIP"
                />
              )}
            </Field>
          </Col>
        </Row>
      </div>
      {submitError && !modifiedSinceLastSubmit && (
        <div className={classNames(styles.cardError, "invalid-feedback")}>
          {submitError}
        </div>
      )}
      {hasCreditCardErrors && (
        <div className={styles.cardErrors}>
          {/* Only show one error at a time for now */}
          {creditCardErrors.slice(0, 1).map(({name, error}) => (
            <div
              key={name}
              className={classNames(styles.cardError, "invalid-feedback")}
            >
              {error}
            </div>
          ))}
        </div>
      )}
    </React.Fragment>
  );
}
