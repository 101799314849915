import React, {useContext, useEffect, useState} from "react";
import classNames from "classnames";
import PaymentReservationContext from "../../context/paymentReservation";
import {
  trackNewReservationConfirmationScreen,
  IdentifyPhoneNumber,
  trackDownloadApp,
} from "../../../lib/analytics";
import classes from "./ReservationSubmitionForm.module.scss";
import {Button} from "react-bootstrap";
import {sendDeepLink} from "../../../lib/reservation-service";
import useUTMData from "../../context/utmData";

const WelcomeMessage = ({phoneNumber, customerId, onHide}) => {
  const {
    state: {color: activeColor, monthlyPayment, startFee},
  } = useContext(PaymentReservationContext);
  const [isAppLinkSending, setIsAppLinkSending] = useState(false);
  const [isAppLinkSent, setIsAppLinkSent] = useState(false);
  const getUTMData = useUTMData();

  const onClickDownloadApp = () => {
    const utmData = getUTMData();
    const data = {...utmData, mobileNumber};

    trackDownloadApp({
      variant: activeColor,
      monthly: monthlyPayment,
      start_fee: startFee,
      data: data,
    });

    IdentifyPhoneNumber(data);

    setIsAppLinkSending(true);

    sendDeepLink(phoneNumber)
      .then(() => {
        setIsAppLinkSending(false);
        setIsAppLinkSent(true);
        if (onHide) {
          onHide();
        }
      })
      .catch((e) => {
        setIsAppLinkSending(false);
      });
  };

  useEffect(() => {
    trackNewReservationConfirmationScreen({
      startFee,
      color: activeColor,
      monthlyPayment,
      customerId,
      location: window.location.href,
      image_url: "", // At the moment the car image comes from the assets folder.
    });
  }, []);

  return (
    <div
      className={classNames(
        "container-fluid use-default gx-lg-4",
        classes.welcomeMessage
      )}
    >
      <h3 className={classNames(classes.WelcomeHeader, "mb-4")}>
        Reservation Confirmed
      </h3>
      <div className={classNames(classes.welcomeRefundableMessage, "mb-3")}>
        This fully-refundable $100 reservation deposit will be applied to your
        security deposit at checkout
      </div>
      <hr />
      <h4 className={classes.checkTexts}>Check your texts</h4>
      <div className={classNames(classes.sentALink, "mb-4")}>
        We’ve sent you a link to download the app.
      </div>
      <div className={classNames(classes.resent, "mb-4")}>
        Didn’t receive it? Tap below to resend it
      </div>

      <Button
        className={`w-100 fw-bold mt-2 ${classes.submitButton}`}
        onClick={onClickDownloadApp}
        disabled={isAppLinkSending}
        variant="secondary"
      >
        Download the App
      </Button>
      {isAppLinkSent && (
        <div className={classes.appDownloadMsg}>
          {`We've sent your app download link!`}
        </div>
      )}
    </div>
  );
};

export default WelcomeMessage;
