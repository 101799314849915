import Cookies from "js-cookie";

// Get branch.io fingerprint
const getBranchFingerprint = () => {
  return new Promise((resolve) => {
    const getBrowserFingerprintId = window.branch?.getBrowserFingerprintId;

    if (typeof getBrowserFingerprintId === "function") {
      try {
        getBrowserFingerprintId((err, id) => {
          if (err) {
            console.error(`Error getting browser fingerprint`);
            resolve();
            return;
          }

          resolve(id);
        });
      } catch (err) {
        resolve();
      }
    } else {
      resolve();
    }
  });
};

async function getAdditionalEventData() {
  const data = {};
  const browser_fingerprint_id = await getBranchFingerprint();

  if (browser_fingerprint_id) {
    data.browser_fingerprint_id = browser_fingerprint_id;
  }

  return data;
}

export async function trackReserveClick(zipcode) {
  window.analytics?.track("Reserve Now Clicked", {
    ...(await getAdditionalEventData()),
    location: "mobile reservation confirmed",
    model: "tesla model 3",
    zipcode,
  });
}

export async function trackConfirmedScreenPage() {
  window.analytics?.page("Reservation Confirmed Screen Viewed ", {
    ...(await getAdditionalEventData()),
    model: "tesla model 3",
  });
}

export async function trackReservationPage() {
  window.analytics?.page("Desktop Reservation Landing", {
    ...(await getAdditionalEventData()),
    model: "tesla model 3",
  });
}

export async function trackProductAddedToWishlist(
  selectedMonthlyPayment,
  customerId
) {
  window.analytics?.track("Product Added to Wishlist", {
    ...(await getAdditionalEventData()),
    name: "Model 3 Reservation",
    brand: "Tesla",
    price: selectedMonthlyPayment,
    customerId,
  });
}

export async function trackhomepageReserveNow() {
  window.analytics?.track("Reserve Now Clicked", {
    ...(await getAdditionalEventData()),
    location: "homepage",
    label: "reserve now",
  });
}

export async function trackhomepageReserve() {
  window.analytics?.track("Reserve Clicked", {
    ...(await getAdditionalEventData()),
    location: "homepage",
    label: "reserve",
  });
}

export async function trackTeslaColorChoice(color) {
  window.analytics?.track("Color Choice Clicked", {
    ...(await getAdditionalEventData()),
    label: "color choice",
    model: "tesla model 3",
    color,
  });
}

export async function trackPaymentPlan(startFee, monthly) {
  window.analytics?.track("Payment Plan Viewed", {
    ...(await getAdditionalEventData()),
    model: "tesla model 3",
    startFee,
    monthly,
  });
}

export async function trackProductViewed({
  name,
  brand,
  variant,
  monthly,
  start_fee,
  url,
}) {
  window.analytics?.track("Product Viewed", {
    ...(await getAdditionalEventData()),
    name,
    brand,
    variant,
    price: "100",
    monthly,
    start_fee,
    url,
  });
}

export function IdentifyReservationPayment({
  firstName,
  lastName,
  phoneNumber,
  email,
  zipcode,
}) {
  window.analytics?.identify({
    firstName,
    lastName,
    phoneNumber,
    email,
    zipcode,
  });
}

export async function IdentifyPhoneNumber(data) {
  const phone = `+1${data.mobileNumber}`;

  window.analytics?.identify({phone});

  window.analytics?.track("Phone Number Entered", {
    ...(await getAdditionalEventData()),
    phone,
    section: data.section,
    category: data.category,
    fbp: Cookies.get("_fbp"),
    "campaign[id]": data.campaignId,
    "campaign[ad_id]": data.campaignAdId,
    "campaign[ad_group]": data.campaignAdGroup,
    clickId: data.clickId,
  });
}

export async function trackSignUpEvent({
  start_pay,
  monthly,
  variant,
  customerId,
}) {
  window.analytics?.track("Sign Up", {
    ...(await getAdditionalEventData()),
    start_pay,
    monthly,
    variant,
    customerId,
  });
}

export async function trackPaymentDoneButtonClick() {
  window.analytics?.track("Payment Done Button Clicked", {
    ...(await getAdditionalEventData()),
    label: "done",
    location: "payment plan",
  });
}

export async function trackLearnMoreClick() {
  window.analytics?.track("Learn More Button Clicked", {
    ...(await getAdditionalEventData()),
    label: "learn more",
    location: "homepage",
    model: "tesla model 3",
  });
}

export async function trackAfterPotentialSavingClick() {
  window.analytics?.track("After Potential Saving Clicked", {
    ...(await getAdditionalEventData()),
    label: "after potential saving",
    location: "homepage",
  });
}

export async function trackImageScrolled(angle) {
  window.analytics?.track("Image Scrolled", {
    ...(await getAdditionalEventData()),
    angle,
    location: "homepage",
  });
}

export async function trackDetailsTabClick(label) {
  window.analytics?.track("Details Tab Click", {
    ...(await getAdditionalEventData()),
    label,
    location: "homepage",
  });
}

export async function trackNewReservationConfirmationScreen({
  startFee,
  color,
  monthlyPayment,
  location,
  image_url,
  customerId,
}) {
  window.analytics?.track("Checkout Started", {
    ...(await getAdditionalEventData()),
    customerId,
    name: "model 3",
    brand: "tesla",
    variant: color,
    monthly: monthlyPayment,
    start_fee: startFee,
    url: location,
    image_url,
  });
}

export async function trackDetailsClick() {
  window.analytics?.track("Information Button Clicked", {
    ...(await getAdditionalEventData()),
    label: "details",
    location: "homepage",
    model: "tesla modal 3",
  });
}

export async function trackNextButtonClick({
  color,
  monthly,
  start_fee,
  data = {},
}) {
  window.analytics?.track("Phone Number Entered", {
    ...(await getAdditionalEventData()),
    name: data?.category ? data.category : "Other",
    brand: "tesla",
    variant: color,
    monthly,
    start_fee,
  });
}

export async function trackRedesignedReserveClick() {
  window.analytics?.track("Reserve_Clicked", {
    ...(await getAdditionalEventData()),
    label: "reservation",
    location: "fastest tab",
    modal: "tesla model 3",
  });
}

export async function trackDownloadAppLinkClicked() {
  window.analytics?.track("Download App Link Clicked", {
    ...(await getAdditionalEventData()),
    type: "transition",
    label: "download autonomy app",
    location: "fastest",
    model: "tesla modal 3",
  });
}

export async function trackDownloadApp({
  variant,
  monthly,
  start_fee,
  data = {},
}) {
  window.analytics?.track("Download the App Clicked", {
    ...(await getAdditionalEventData()),
    name: data?.category ? data.category : "Other",
    brand: "tesla",
    variant,
    monthly,
    start_fee,
  });
}

export async function trackGetStartedClicked({start_fee, monthly, location}) {
  window.analytics?.track("Get Started Clicked", {
    ...(await getAdditionalEventData()),
    start_fee,
    monthly,
    location,
  });
}

export async function trackConfirmAndPayClicked() {
  window.analytics?.track("Reservation Submitted", {
    ...(await getAdditionalEventData()),
    location: "homepage",
  });
}

export function IdentifyReservationSuccess({userId, email}) {
  window.analytics?.identify(userId, {
    // documentation mentions userId as first param
    userId, // Also passing here in case its needed for braze or segment
    email,
  });
}

export async function trackHowItWorksClicked() {
  window.analytics?.track("How it Works Video Played", {
    ...(await getAdditionalEventData()),
    video: "how it works",
  });
}

export async function trackFormCloseClick() {
  window.analytics?.track("Form Exited", {
    ...(await getAdditionalEventData()),
    form: "reservation",
    model: "tesla model 3",
  });
}

export async function buttonClickedEvent(label, location, type) {
  window.analytics?.track("Button Clicked", {
    ...(await getAdditionalEventData()),
    label: label,
    location: location,
    type: type,
  });
}

export async function trackComponentViewed(key) {
  window.analytics?.page(key, {
    ...(await getAdditionalEventData()),
    model: "tesla model 3",
  });
}
