import Modal from "react-bootstrap/Modal";
import parser from "html-react-parser";
import CloseButton from "../CloseButton/CloseButton";
import styles from "./StartPaymentModal.module.scss";
import classNames from "classnames";

export default function RefundableModal(props) {
  const content = props.refundableModalContent?.fields?.body?.[0].fields;
  const {headline_1, headline_2, content_1, content_2} = content || {};

  return (
    <Modal
      {...props}
      centered
      contentClassName={styles.modalContent}
      dialogClassName="modal-lg"
    >
      <Modal.Header className={styles.header}>
        <Modal.Title className={styles.title}>{headline_1}</Modal.Title>
        <CloseButton onClose={props.onHide} />
      </Modal.Header>
      <Modal.Body>
        {content_1 && <div className={styles.content}>{parser(content_1)}</div>}
        {headline_2 && (
          <div className={classNames(styles.title, "mt-4", "mb-2")}>
            {headline_2}
          </div>
        )}
        {content_2 && <div className={styles.content}>{parser(content_2)}</div>}
      </Modal.Body>
    </Modal>
  );
}
