import {useRouter} from "next/router";

export default function useUTMData() {
  const {pathname, query} = useRouter();

  function getSection(pathname) {
    switch (pathname) {
      case "/":
        return "Homepage";
      case "/tesla/model-y":
        return "Model Y";
      case "/tesla/model-3":
        return "Model 3";
      default:
        return "Other";
    }
  }

  return function getUTMData() {
    const getCookie = (cookieName) => {
      let cookie = {};
      document.cookie.split(";").forEach(function (el) {
        let [key, value] = el.split("=");
        cookie[key.trim()] = value;
      });
      return cookie[cookieName] ? cookie[cookieName] : "";
    };

    const {
      utm_cid = "",
      utm_cpn = "",
      utm_aid = "",
      utm_term = "",
      utm_sid = "",
      utm_adgrp = "",
      fbclid = "",
      gclid = "",
    } = query || {};
    const section = getSection(pathname);
    const category = pathname === "/tesla/model-y" ? "Model Y" : "Model 3";

    const campaignId = utm_cid || utm_cpn;
    const campaignAdId = utm_aid || utm_term;
    const campaignAdGroup = utm_sid || utm_adgrp;
    const fbClid = fbclid;
    const gClid = gclid;

    const data = {
      section,
      category,
      campaignId,
      campaignAdId,
      campaignAdGroup,
      fbClid,
      gClid,
      fbm: getCookie("_fbm"),
    };

    return data;
  };
}
