import PropTypes from "prop-types";
import Form from "react-bootstrap/Form";

TextField.propTypes = {
  ignoreError: PropTypes.bool,
  label: PropTypes.string,
};

/**
 * @description Component used to map react-final-form field render props to boostrap form control
 */
export default function TextField(props) {
  const {
    ignoreError,
    input,
    label,
    labelClassName,
    wrapperClassName,
    meta: {error, invalid, touched},
    ...otherInputProps
  } = props;

  return (
    <Form.Group controlId={input.name} className={wrapperClassName}>
      {label && <Form.Label className={labelClassName}>{label}</Form.Label>}
      <Form.Control
        {...input}
        {...otherInputProps}
        isInvalid={!ignoreError && touched && invalid}
      />
      {!ignoreError && touched && error && (
        <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
      )}
    </Form.Group>
  );
}
