import React from "react";
import Image from "next/image";
import styles from "./CloseButton.module.scss";

const CloseButton = ({onClose}) => {
  return (
    <button className={styles.button}>
      <Image
        src="/svg/closeModal.svg"
        width={15}
        onClick={onClose}
        height={15}
        alt="Close button"
      />
    </button>
  );
};

export default CloseButton;
