import Link from "next/link";
import classNames from "classnames";
import React from "react";
import styles from "./ReserveNowDisclosures.module.scss";

export default function ReserveNowDisclosures({className}) {
  return (
    <div
      className={classNames(
        className,
        styles.disclosures,
        "content_with_pink_links"
      )}
    >
      <p>{`By submitting my info, I agree to be contacted about Autonomy products, including through automated calls or texts. This is not a condition of purchase.`}</p>
      <p>Eligibility check is required prior to subscription purchase.</p>
      <p>
        {`By tapping "Place Reservation," you agree to Autonomy's `}
        <Link href="/tos" legacyBehavior={true}>
          <a target="_blank">TOS</a>
        </Link>
        {` and `}
        <Link href="/esign" target="_blank" legacyBehavior={true}>
          <a target="_blank">E-Sign Policies</a>
        </Link>
        {`. You also consent to Autonomy contacting you by mobile
        phone/text for the purposes of assisting you to subscribe to an Autonomy
        vehicle. Currently only available in select US locations. Expanding
        soon.`}
      </p>
    </div>
  );
}
